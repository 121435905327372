.article {
    margin-top:2em;
    font-size: large
}

.article-image {
    margin:1em;
    /* text-align: center; */
}

.article-image-caption {
    color: var(--osu-gray); 
    font-size: medium; 
}

.icon-size {
    font-size: 7em;
}

.content-section {
    margin-top:1em;
}

.fa-7x {
    font-size: 7em !important;
}

.fa-8x {
    font-size: 8em !important;
}

.fa-10x {
    font-size: 10em !important;
}

.achieve-pbar-title {
    color: var(--osu-gray); 
    font-weight: 100;
    font-size: 0.8em;
}

.achievement-body {
    border-top: 1px solid var(--app-border-gray);
    min-height: 6em;
    padding-top: 1.5rem;
    margin: 1.5rem 0;
}
.achievement-info-section {
    display: flex;
    flex-direction: column
}
@media(min-width: 768px) {
    .achievement-info-section {
        flex-direction: row
    }
    .achievement-heading {
        margin-left: auto
    }
}

.achievement-summary-header {
    font-size: 1.1rem;
}
.level-bar {
    --bar-height: .25rem;
    --stop-tick-height: calc(var(--bar-height) * 4);
    --circle-dimensions: calc(var(--bar-height) * 2);
    --meter-width: 2px;
    --starting-level-text-position: -22px;
    --starting-rewards-indicator-position: -19px;

}

.level-bar {
    margin: 4rem 1rem;
    position: relative;
}
.level-bar .unfilled {
    height: calc(var(--stop-tick-height)/4);
    border-radius: 1rem;
    background-color: var(--app-border-gray);
    padding: 0 1rem;
}
.level-bar .filled {
    height: 100%;
    border-radius: 1rem;
    background-color: var(--osu-red);
    position: relative;
    z-index: 5;    
    padding-left: 1rem;
    left: -1rem;
    
}
.level-bar .circle {
    width: var(--circle-dimensions);
    height: var(--circle-dimensions);
    background-color: var(--osu-red);
    border-radius: 50%;
    position: absolute;
    right: calc(-1*(var(--bar-height)));
    top: calc(-1*(var(--bar-height)/2));
}
.level-bar .level-stops {
    display: flex;
    text-align: center;
    margin-top: calc((var(--stop-tick-height) - var(--bar-height))/2);
}

.level-bar .level-stops .meter {
    padding-top: .75rem;
    position: relative;
    font-size: .875rem;
}
.level-bar .level-stops .meter:after {
    content: '';
    width: var(--meter-width);
    height: var(--stop-tick-height);
    background: var(--app-border-gray);
    position: absolute;
    bottom: 100%;
    right: calc(var(--meter-width) * (-1/2));

}
.level-bar .level-text {
    position: absolute;
    width: 100%;
    right: -50%;
}
.level-bar .points-text {
    position: absolute;
    width: 100%;
    right: -50%;
    top: 225%;
}
.level-bar .rewards-indicator {
    position: absolute;
    bottom: calc(100% + var(--stop-tick-height));
    width: 100%;
}
@media (max-width: 778px) { 
    .level-bar .level-text {
        display: none;
    }
    .level-bar .points-text {
        top: 0%
    }
} 
@media (max-width: 716px) {
    .level-bar .points-text span{
        display: none;
    }
}
.level-bar.no-stop {
    margin: 0
}
.level-bar.no-stop .unfilled {
    padding: 0
}
.level-bar.no-stop .filled {
    padding-left: 0;
    left: 0px
}
.starting-value{
    left: var(--starting-level-text-position);
    width: 40px !important;
}
.rewards-indicator.starting-value{
    left: var(--starting-rewards-indicator-position);
}
.points-text.starting-value{
    left: -50px;
    width: 100px !important;
}
.content-card {
    min-height: 390px;
    border-radius: 4px;
}

.content-card:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.04);
}

.content-card__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 3px;
}

.content-card__heading-wrapper {
    width: 100%
}

.content-card__type {
    background: white;
    color: black;
    position: relative;
    top: 1em;
}

.content-card__title {
    font-weight: 600;
    color: black;
    margin: 0;
    text-align: left;
    word-break: break-word;
    font-size: 1em;
}

.content-card__date {
    color: var(--osu-gray);
    font-weight: 300;
    text-align: left;
    margin: 0;
}

.content-card__description {
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    color: var(--osu-gray-medium);
    flex-grow: 1;    
    padding-top: .25rem;
    border-top: 1px solid var(--app-border-gray);
}

.progress-container {
    width: 70px;
    height: 70px;
    margin: auto auto 1em 1em;
    position: relative;
}

.progress-percentage {
    position: absolute;
    top: 0; right: 0;
    bottom: 0; left: 0;
    transform: translate(-3%, 2%);
    text-align: center;
    vertical-align:middle;
    line-height: 6em;
}

.progress-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkmark {
    margin: 0 0 0 0;
    padding: 2em 1em 1em 1em;
}

.content-card__action-sheet {
    font-weight: 600;
    border-color: var(--osu-gray);
    color: var(--osu-gray);
    width: 90%;
    align-self: center
}

.content-card__body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.featured-card-heading {
    color: white;
    margin: 0;
    width: 100%;
    font-size: 1em;
}
.featured-text-wrapper {
    min-height: 6rem;
}
.featured-button {
    width: 90%;
}
.featuredContentCard button {
    text-transform: capitalize;
    font-weight: 600;
    border-color: var(--osu-gray);
    color: var(--osu-gray);
}
.featuredContentCard:hover {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
}
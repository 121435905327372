.achievement-title {
    font-size: x-small;
    line-height: 0.5rem;
    width: 3.7rem;
}

@media (max-width: 575.98px) {
    .badges-container {
        border-top: 1px solid var(--app-border-gray);
        padding-top: .5rem;
        margin-top: .5rem;
    }
}

@media (max-width: 691px) {
    .profile-progress-container {
        width: 90px;
        min-width: 90px;
        height: 90px;
        margin: auto auto 1em auto !important;
        position: relative;
    }
}

.profile-progress-container {
    width: 90px;
    min-width: 90px;
    height: 90px;
    margin: auto auto 1em 0;
    position: relative;
}

.profile-summary-header {
    font-size: 1.1rem;
}

.profile-history-empty {
    font-size: 1em;
}

.profile-title {
    font-size: 1.2em;
}
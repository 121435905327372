.underline{
    border-bottom: 2.25px solid var(--app-border-gray);
}

.light-underline{
    border-bottom: .25px solid var(--app-border-gray);
}

.remove-button-up {
    margin: auto, auto;
}

.h4-step-label {
    margin-bottom: 0;
}

.confirm-button-position {
    margin-left: 3px;
    margin-bottom: 3px;
}

.cancel-button-position {
    margin-left: 3px;
}

.light-gray {
    color: var(--osu-gray);
}

.cart-empty {
    font-size: 1.3em;
}
.rewards-sidebar {
    flex-direction: column;
    background-color: rgba(255, 255, 255, .9);
    position: absolute;
    width: 100%;
    border-right: 1px solid var(--app-border-gray);
}

.available-levels {
    border-bottom: 1px solid var(--app-border-gray);
    overflow: scroll;
    padding: .25em 0 1em 0;
    margin-bottom: 1em;
}
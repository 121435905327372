label {
    display: inline-block; 
    padding: 5px; 
    position: relative; 
    padding-left: 20px;
    cursor: pointer;
}

.middle {
    vertical-align: middle
}

.block-me {
    display: inline-block;
    position: relative;
}

input:checked + span {
    color: white;
    background-color: #6c757d;
    border-color: #6c757d;
}

.quiz-desc {
    font-size: 1.2em;
    margin-bottom: 1em;
    margin-top: .3em;
}

.complete-title {
    vertical-align: middle;
    font-size: 1.5em;
}

.question {
    font-size: 1.1em;
}

.questionResult {
    left: 2rem;
    position: absolute;
    margin-top: 3px;
}
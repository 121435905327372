.rewards-title-containter{
    color: white;
    background-color: black;
    height: 10em;
}

.rewards-title {
    display: block;
    margin: 1.25em 1em 1.25em .25em;
}

.sticky-rewards-nav {
    top: var(--header-height);
    position: sticky;
    z-index: 100;
}

.boldened-alert {
    font-weight: bold;
}

@media(min-width: 992px) { 
    .sticky-rewards-nav { 
        display: none;
    } 
    .sticky-side-bar { 
        height: 100%;  
        top: var(--header-height);
        position: sticky !important;  
        display: block;
    } 
}
.article {
    margin-top:2em;
    font-size: large
}

.article-image {
    margin:1em;
    /* text-align: center; */
}

.article-image-caption {
    color: var(--osu-gray); 
    font-size: medium; 
}

.article-banner-image {
    height: 350px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.content-section {
    margin-top:1em;
}
@import "./imports";

body {
  margin: 0;
  font-family: proximanova,  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --root-background-color: #ffffff;
  --root-text-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: var(--root-background-color);
  color: var(--root-text-color);
  height: 100vh;
}

#osunavbar {
  position: relative !important;
}

@media(max-width: 767px) {
  #page-content {
      min-height: calc(100vh - 42px - 60px - 0.50rem - 287px); // root - navbar - header - main y margins - footer
  }
  footer {
      height: 287px;
  }
}

@media(min-width: 768px) {
  #page-content {
      min-height: calc(100vh - 42px - 60px - 0.50rem - 200px); // root - navbar - header - main y margins - footer
  }
  footer {
      height: 200px;
  }
}
.rewards-image-wrapper img {
    height: 15em;
    object-fit: scale-down
}
.rewards-overlay {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}
.rewards-overlay p {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem;
    background-color: rgba(255, 255, 255, .6)
}
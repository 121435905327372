.level-card-footer {
    display: flex;
    flex-direction: column;
    border-top: 1px solid var(--app-border-gray);
}
@media (min-width: 576px) { 
    .level-card-footer {
        flex-direction: row;
    }
    .level-card-tile {
        flex-grow: 1    
    }
    .level-text {
        white-space: nowrap;
    }
}
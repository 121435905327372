.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.progressHeader {
    text-align: left;
}

.sticky {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20;
}
.progress-line-container {
    margin-top: auto;
    margin-bottom: auto;
    width: 150px;
}

@media(min-width: 768px) {
    .progress-line-title {
        width: 272px !important;
        top: 24px !important;
    }
    .progress-line-container {
        width: 272px !important;
    }
    .right-of-begin-progress-line-title {
        text-indent: 100px !important;
    }
    .right-of-center-progress-title {
        text-indent: 150px !important;
    }

    .left-of-center-progress-line-title {
        color: black !important;
        text-indent: 10px !important;
    }

    .center-black-progress-title {
        text-indent: 100px !important;
        color: black !important;
    }
}

.progress-line-title {
    width: 272px;
    color: white;
    font-weight: bold;
    font-size: .8125em;
    position: absolute;
    line-height: 2em;
    top: 22px;
}

.right-of-begin-progress-line-title {
    text-indent: 60px;
}

.right-of-center-progress-title {
    text-indent: 77px;
}

.left-of-center-progress-line-title {
    color: black;
    text-indent: 5px;
}

.center-black-progress-title {
    color: black;
    text-indent: 50px;
}

.menu-toggler {
    color: #999;

}

.menu-header a {
    color: var(--osu-gray-dark);
    font-weight: 600;
    transition: ease-in-out color .15s;
}
@media(max-width: 376px) {
    .menu-header .navbar-toggler-icon {
        width: 1em !important;
    }
}
@media(min-width: 768px) {
    .menu-header {
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-bottom: 0 !important;
        float: none;
    }
}

.menu-header a:hover {
    text-decoration: underline;
}

.icon {
    font-size: 3em;
}

.progressBar {
    margin-top: auto;
    margin-bottom: auto;
}

.no-right-margin {
    margin-right: 0 !important;
}



.clickable {
    cursor: pointer;
}

.active-header {
    color: rgba(0, 0, 0, 0.9) !important;
}

.inactive-header {
    color: rgba(0, 0, 0, 0.6) !important;
}
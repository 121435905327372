.step-container {
    display: flex;
    flex-direction: column
}

.step-container .section-heading {
    font-size:  1.5rem;
    margin-left: 1rem;
}

.step-container .section-circle {
    display: flex;
    width: calc(2rem + 1px);
    height: calc(2rem + 1px);
    background-color: var(--osu-red);
    border-radius: 50%;
    color: white;
    font-weight: 600
}

.step-container .section-circle .circle-text {
    align-self: center;
    width: 100%;
    text-align: center;
}

.step-container .section-body {
    border-left: 1px solid var(--app-border-gray);
    margin-left: 1rem;
    padding-left: 2rem;
    min-height: 2rem
}

.step-container .step:last-child .section-body {
    border: none
}
.article {
    margin-top:2em;
    font-size: large
}

.article-banner-image {
    height: 350px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.article-image {
    margin:1em;
    /* text-align: center; */
}

.article-image-caption {
    color: var(--osu-gray); 
    font-size: medium; 
}

.content-section {
    margin-top:1em;
}

.content-section a {
    color: var(--action-blue) !important;
    text-decoration: underline;
}
.content-section a:hover {
    color: var(--osu-gray) !important;
    text-decoration: underline;
}

.ql-video-wrapper {
    position: relative;
    padding-bottom: 45%;
    height: 0;
    overflow: hidden;
}
.ql-video-wrapper iframe{
    left: 10%;
    top: 0;
    height:100%;
    width:80%;
    position:absolute;
}

.article-section-title {
    font-size: 1.1em;
}

.article-related-subhead {
    font-size: 1.2em;
}

.article-subtext {
    font-size: 1.4em;
}
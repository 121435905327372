/*OSU Color Variables*/
:root {
  --osu-red: rgb(187, 0, 0);
  --osu-red-dark: #960000;
  --osu-gray-x-light: #b3b3b3;
  --osu-gray-light: #858585;
  --osu-gray: #666666;
  --osu-gray-medium: #5C5C5C;
  --osu-gray-dark: #333333;
  --osu-gold: #dcaa38;
  --osu-green: #73792d;
  --osu-violet: #851E5E;
  --osu-teal: #26686D;
  --osu-blue: #476c97;
  --app-border-gray: #dadce0;
  --action-blue: #007FFF;
  /*Header height used for rewards menu sticky nav. Update this if the app navigation height changes*/
  --header-height: 56px
}
@media (max-width: 767.98px) {
	.container {  
		max-width: none;
	}
}
.app-container {
  margin-top: 1.5rem
}
.app-nav-container {
	padding: 0 1rem;
	margin: 0 auto;
  max-width: 1440px;
  z-index: 200;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'proximanova', 'Arial', sans-serif;
}

.osu-red-btn {
  background-color: var(--osu-red);
  color: white;
  border-color: rgba(187, 0, 0, .5)
}

.osu-red-link {
  color: var(--osu-red-dark);
  font-weight: 600;
}

.osu-red-btn:hover {
  background-color:  #860303;
  border-color: #9a0303;
}

.osu-red-link:hover {
  color: #4b0000;
  font-weight: 600;
}
.osu-red-btn.disabled {
  color: #fff;
  background-color: var(--osu-gray);
  border-color: var(--osu-gray-dark);
}

.osu-red-btn.disabled:hover {
  color: #fff;
  background-color: var(--osu-gray);
  border-color: var(--osu-gray-dark);
}

.osu-link-primary {
  color: var(--osu-gray-dark);
  cursor: pointer;
} 

.osu-link-primary, .osu-link-primary:focus, .osu-link-primary:active {
  color: var(--osu-gray);
  cursor: pointer;
}

.osu-link-primary:hover, .osu-link-primary a:hover {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: underline;
}

.osu-link-secondary {
  color: var(--osu-gray-dark);
  font-weight: 600;
  cursor: pointer;
} 

.osu-link-secondary, .osu-link-secondary:focus, .osu-link-secondary:active {
  color: var(--osu-gray);
  cursor: pointer;
}

.osu-link-secondary:hover, .osu-link-secondary a:hover {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: underline;
}

.osu-red-btn.floating {
  box-shadow: 4px 4px 10px grey;
}
.action-sheet {
  border-top: 1px solid var(--app-border-gray);
}
.hyperlink {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.no-row-margins {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-col-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 100px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}
.app-container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 992px) {
  .app-container {
      max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .app-container {
    max-width: 1140px;
  }
}
.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media (min-width: 1420px) {
  .col-xl-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-xxl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-xxl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-xxl-0 {
    margin-left: 0;
  }
  .offset-xl-xxl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-xxl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-xxl-3 {
    margin-left: 25%;
  }
  .offset-xl-xxl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-xxl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-xxl-6 {
    margin-left: 50%;
  }
  .offset-xl-xxl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-xxl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-xxl-9 {
    margin-left: 75%;
  }
  .offset-xl-xxl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-xxl-11 {
    margin-left: 91.666667%;
  }
}


@media (min-width: 1600px) {
  .col-xxl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 10%;
    flex: 0 0 10%;
    max-width: 10%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%;
    max-width: 80%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 90%;
    flex: 0 0 90%;
    max-width: 90%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 10%;
  }
  .offset-xxl-2 {
    margin-left: 20%;
  }
  .offset-xxl-3 {
    margin-left: 30%;
  }
  .offset-xxl-4 {
    margin-left: 40%;
  }
  .offset-xxl-5 {
    margin-left: 50%;
  }
  .offset-xxl-6 {
    margin-left: 60%;
  }
  .offset-xxl-7 {
    margin-left: 70%;
  }
  .offset-xxl-8 {
    margin-left: 80%;
  }
  .offset-xxl-9 {
    margin-left: 90%;
  }
}

/*Typography*/
.subheading {
  color: var(--osu-gray);    
  font-size: 1em;
}

/*Effects*/
.paper {
  box-shadow: 2px 2px 10px var(--osu-gray-light);
  background-color: white;
  border: none
}
.paper-light {
  box-shadow: 1px 1px 8px var(--osu-gray-x-light);
  background-color: white;
  border: none
}

/* Navbar fix */
.App #osu_navbar .container{
  margin: 0 auto;
}